import React, { useState, useEffect } from "react";
import axios from "axios";

export default function News() {
  const [mainData, setMainData] = useState([]);

  useEffect(() => {
    axios
      .get(`https://coritoindia.github.io/stockvia-data/sample2.json`)
      .then((res) => {
        setMainData(res.data);
      });
  }, []);

  return (
    <div className="updates-section">
      <main className="weekly-stocks">
        <h3>Top Trending Stocks News Today</h3>
        <p>Get highly curated stock recommendations by experts & top firms!</p>
        <div className="">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>Company Name</th>
                <th>Current Price</th>
                <th>Target</th>
                <th>Recommeded By</th>
              </tr>
            </thead>

            <tbody>
              {mainData.map((i) => {
                return (
                  <tr>
                    <td>{i.companyName}</td>
                    <td>{i.cmp}</td>
                    <td>{i.target}</td>
                    <td>{i.firm}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </main>
      <aside className="quarterly-stocks">
        <h5>UPCOMING QUARTERLY RESULTS</h5>
        {/* <p>Get highly curated stock recommendations by experts & top firms!</p> */}
        <div className="">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>Company Name</th>
                <th>Date</th>
              </tr>
            </thead>

            <tbody>
              {mainData.map((i) => {
                return (
                  <tr>
                    <td>{i.companyName}</td>
                    <td>{i.cmp}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </aside>
    </div>
  );
}
